import { documentReady, hideElement, qs, toggleVisible } from '@utils/toolbox';
documentReady(() => {
	qs('[data-cross-domain-menu-button]')?.addEventListener('click', () => {
		toggleVisible('[data-cross-domain-menu]');
	});

	// Hide on click outside
	document.addEventListener('click', (e) => {
		if (!e.target.closest('[data-cross-domain-menu]') && !e.target.closest('[data-cross-domain-menu-button]')) {
			hideElement('[data-cross-domain-menu]');
		}
	});
});
