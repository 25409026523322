import * as toolbox from '@utils/toolbox';
import { ConnectApi } from '@js/plugins/connectApi';
import { trackEvent } from '@utils/googleDataLayer';

bindGlobals({
	toolbox: toolbox,
	trackEvent: trackEvent,
	debugMenu: toolbox.debugMenu,
	ConnectApi: ConnectApi,
	forceServer: toolbox.forceServer,
	debugTranslationKeys: toolbox.debugTranslationKeys,
	getUrlVars: toolbox.getUrlVars
});


function bindGlobals (globals) {
	Object.entries(globals).forEach(([key, value]) => {
		window[key] = value;
	});
}
