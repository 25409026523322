import '@sass/global/global.scss';
import { Civet } from '@js/plugins/civet.js';

// import '@utils/traktorPlayground.js';

// See: https://vitejs.dev/guide/backend-integration.html
import 'vite/modulepreload-polyfill';
import '@utils/_globals.js';
import '@module/LanguageSelector/LanguageSelector.js';
import '@module/DomainSelector/DomainSelector.js';
import { EventDispatcher, bindIntersectionObserver, classList, documentReady, getCookie, hideElement, isLocalStorageAvailable, isVisible, l, lc, lm, logEvent, lreplace, overrideTranslationFunctions, qs, qsa, sleep, storedItemCreate, storedItemRead, urlParameterDelete } from '@utils/toolbox.js';
import { CookieNotice } from '@module/CookieNotice/CookieNotice.js';
import { LanguageSelector } from '@module/LanguageSelector/LanguageSelector.js';


import { initialisePolyfills } from '@js/polyfills/index.js';
import { eventCategories, events } from '@js/utils/event';

// Assume adblockers are enabled unless adframe.js is loaded and states otherwise
window.adBlockEnabled = (typeof window.adBlockEnabled === 'boolean') ? window.adBlockEnabled : true;


const Main = {
	init () {
		try {
			initialisePolyfills();
			this.setupNav();
			this.setupHandlebarsHelpers();
			this.checkLocalStorageVersion();
			this.backForwardCache();
			this.LanguageSelector = new LanguageSelector();
			this.cookieNotice = new CookieNotice();
			this.overrideTranslationFunctions();
			this.initAnalytics();
			urlParameterDelete('referral_source');
			this.setWindowHeightCSSVar();
			window.addEventListener('resize', this.setWindowHeightCSSVar);
		} catch (e) {
			console.error(e);
			Civet.reportError(e);
		}
	},

	setWindowHeightCSSVar () {
		const doc = document.documentElement;
		doc.style.setProperty('--window-height', `${window.innerHeight}px`);
	},

	async initAnalytics () {
		// This sleep is to prevent the observer from being added before the page is fully loaded. For example: images and recent searches.
		await sleep(0);
		if (js_params?.controllerClass?.includes('index')) {
			const sections = qsa('section[data-section]');

			sections.forEach((section) => {
				bindIntersectionObserver(section, () => {
					if (!section.classList.contains('scrolled') && window.scrollY !== 0) {
						section.classList.add('scrolled');
						logEvent(events.scrollIndex, eventCategories.scroll, section.dataset.section);
					}
				}, { root: null, rootMargin: '30%', threshold: 0.1 });
			});

			if (qs('footer')) {
				bindIntersectionObserver(qs('footer'), () => {
					if (!qs('footer').classList.contains('scrolled') && window.scrollY !== 0) {
						qs('footer').classList.add('scrolled');
						logEvent(events.scrollIndex, eventCategories.scroll, 'footer');
					}
				}, { root: null, rootMargin: '30%', threshold: 0.1 });
			}
		}
	},

	backForwardCache () {
		// https://web.dev/bfcache/
		// https://stackoverflow.com/questions/8788802/prevent-safari-loading-from-cache-when-back-button-is-clicked
		window.addEventListener('pageshow', (event) => {
			// Send another pageview if the page is restored from bfcache.
			if (event.persisted) {
				if (isVisible(window?.LoadingOverlay?.elements?.wrapper)) {
					console.log('page restored from bfcache, hiding loading overlay');
					EventDispatcher.dispatch('loadingOverlay', false);
				}
			}
		});
	},
	overrideTranslationFunctions () {
		if (getCookie('showTranslationKeys')) {
			overrideTranslationFunctions();
		}
	},
	checkLocalStorageVersion () {
		if (!isLocalStorageAvailable) {
			return;
		}
		const { localStorageVersion } = js_params;
		const currentLocalStorageVersion = storedItemRead('localStorageVersion');
		if (currentLocalStorageVersion !== localStorageVersion) {
			localStorage.clear();
			storedItemCreate('localStorageVersion', localStorageVersion);
		}
	},
	setupNav () {
		const showMobileNav = () => {
			classList('#abHeaderBurgerMenu').toggle('abHeader__bun--active');
			classList('nav').toggle('abHeader__nav--visible');
		};

		const hideMobileNav = () => {
			classList('#abHeaderBurgerMenu').remove('abHeader__bun--active');
			classList('nav').remove('abHeader__nav--visible');
		};

		document.addEventListener('click', (e) => {
			const elem = e.target;

			if (elem.id === 'abHeaderBurgerMenu') {
				e.preventDefault();

				showMobileNav();
			} else {
				hideMobileNav();
			}
		});

		window.addEventListener('resize', e => hideMobileNav());
	},
	setupHandlebarsHelpers () {
		try {
			if (typeof Handlebars !== 'undefined') {
				Handlebars.registerHelper(
					{
						localized () {
							const args = Array.prototype.slice.call(arguments, 0, -1);

							return new Handlebars.SafeString(l.apply(window, args));
						},
						localizedKeyParams (key) {
							const dictionaryParameters = Array.prototype.slice.call(arguments, 1, -1);
							const text = l(key);
							const dictionary = {};

							const keysInText = text.match(/\$\w+/g);

							if (keysInText != null && keysInText.length) {
								for (let i = 0; i < keysInText.length && i < dictionaryParameters.length; i++) {
									dictionary[keysInText[i].substring(1)] = dictionaryParameters[i];
								}
							}

							return new Handlebars.SafeString(lreplace(key, dictionary));
						},
						localizedCurrency (price) {
							const res = lc(price);
							return new Handlebars.SafeString(res);
						},
						staticContentPrefix () {
							const res = '/static/v' + js_params.version;
							return new Handlebars.SafeString(res);
						},
						localizedFormated (price) {
							const res = lc(price, true);
							return new Handlebars.SafeString(res);
						},
						handleMissingLogo (targetImg) {
							const missingAirlineLogo = lm('/assets/images/airlines/iata/unknown.png');

							if (targetImg.src !== missingAirlineLogo) {
								targetImg.src = missingAirlineLogo;
							}
						}
					});
			}
		} catch (e) {
			console.log(e);
		}
	}
};

window.Main = Main;

documentReady(function () {
	if (import.meta.env.DEV) {
		console.log('Main: ready');
	}

	if (import.meta.env.LEGACY) {
		console.log('Build: legacy');
	}


	Main.init();

	const targetElement = function (currentElement, step) {
		// if it does not have the tabindex attribute.
		if (!currentElement.hasAttribute('tabindex')) {
			return false;
		}

		const tabIndex = Number(currentElement.getAttribute('tabindex')) + step;
		const nextElement = qs("[tabindex='" + tabIndex + "']");

		// lets only move to the next element in line if there is one with tabindex $currentElement + 1
		if (nextElement) {
			setTimeout(function () {
				nextElement.focus();
			}, 5);
		}
	};

	EventDispatcher.addEventListener('targetNextInput', function (element) {
		targetElement(element, 1);
	});

	EventDispatcher.addEventListener('targetPreviousInput', function (element) {
		targetElement(element, -1);
	});

	// Workaround for the internet explorer's placeholder attribute for input
	let internetExplorer = /msie (\d+),|\./i.exec(navigator.userAgent);

	if (internetExplorer) {
		internetExplorer = Number(internetExplorer[1]);

		if (internetExplorer <= 11) {
			const inputPlaceholderEvent = function (input, _event) {
				'use strict';

				const value = input.value;
				const placeHolder = input.getAttribute('placeholder');

				// if placeholder is not specified
				if (!placeHolder) {
					return;
				}

				if (_event.type === 'focusin') {
					if (value == placeHolder) {
						input.value = '';
					}
				} else if (_event.type === 'focusout') {
					if (!value) {
						input.value = placeHolder;
					}
				}
			};

			document.body.addEventListener('focusin', (event) => {
				if (event.target.matches('input[type=text]')) {
					inputPlaceholderEvent(event.target, event);
				}
			});
			document.body.addEventListener('focusout', (event) => {
				if (event.target.matches('input[type=text]')) {
					inputPlaceholderEvent(event.target, event);
				}
			});

			qsa('input[type=text]')?.forEach(function (element) {
				const placeholder = element.getAttribute('placeholder');
				if (!element.value && placeholder) {
					element.value = placeholder;
				}
			});
		}
	}

	// Tooltips and pseudolinks
	document.body.addEventListener('click', function (event) {
		if (!event.target.closest('.abSearchBox__checkbox--info')) {
			qsa('div.abTooltip').forEach(tooltip => tooltip.classList.remove('abTooltip--visible'));
		}

		if (!event.target.closest('.filterInfo')) {
			qsa('.filterTooltip').forEach(tooltip => tooltip.classList.remove('filterTooltip--visible'));
		}

		if (!(event.target.closest('.abBuyBtn__recommendedLabel') || event.target.closest('.abBuyBtn__bestLabel'))) {
			qsa('.abBuyBtn__labelTooltip').forEach(tooltip => tooltip.classList.remove('visible'));
		}

		if (!(event.target.closest('.icon-circle-question-mark'))) {
			qsa('.question-mark--text').forEach(tooltip => tooltip.classList.remove('active'));
		}

		if (event.target.closest('[data-pseudolink]')) {
			window.location.href = window.location.origin + event.target.closest('[data-pseudolink]').dataset.pseudolink;
		}

		if (event.target.closest('[data-pseudolinkblank]')) {
			window.open(window.location.origin + event.target.closest('[data-pseudolinkblank]').dataset.pseudolinkblank, '_blank');
		}
	});


	const ads = qsa('.adspace');

	if (ads.length && !window.adBlockEnabled) {
		const mutationConfig = { childList: true };

		const mutationCallback = (mutationList, observer) => {
			for (const mutation of mutationList) {
				if (mutation.type === 'childList') {
					logEvent(events.observeAdvertisement, eventCategories.observe);
				}
			}
		};

		const mutationObserver = new MutationObserver(mutationCallback);

		ads.forEach((ad) => {
			mutationObserver.observe(ad, mutationConfig);
		});
	}

	// fix adblock thingie
	qsa('.google-ad').forEach(function (element) {
		if (element.clientHeight === 0) {
			hideElement(element);
		}
	});

	if (window.adBlockEnabled === true) {
		document.documentElement.classList.add('adblock');
	}
});
