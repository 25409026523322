import { EventDispatcher, getCookie, l, logEvent, qs, setCookie, slideUp } from '@utils/toolbox';
import '@utils/cookieBasedFeatures';
import { Modal } from '@js/components/modal';
import { eventCategories, events } from '@js/utils/event';
const COOKIE_CONSENT_BITMAP = {
	NONE: 0,
	FUNCTIONAL: 1,
	PERFORMANCE: 2,
	TARGETING: 4
};
export class CookieNotice {
	constructor () {
		this.html = document.getElementById('cookie-notice');

		this.consentCode = parseInt(getCookie('consent'), 10) || 0;

		if (js_params.domain === 'uberflieger.de') {
			return;
		}

		if (!this.html) {
			return;
		}

		// const isConsentGiven = this.consentCode > 0;
		// const isConsentDeclined = this.consentCode === 0;
		const isConsentNotSet = getCookie('consent') === null;

		if (this.isTargetingCookiesSet) {
			EventDispatcher.dispatch('cookieNotice:accept');
		}

		if (isConsentNotSet) {
			this.html.classList.add('cookie-notice--visible');
		}

		if (js_params.isShowresult) {
			this.html.classList.add('cookie-notice--showresult');
		}

		this.events();
	}

	get isFunctionalCookiesSet () {
		return Boolean(this.consentCode & COOKIE_CONSENT_BITMAP.FUNCTIONAL);
	}

	get isPerformanceCookiesSet () {
		return Boolean(this.consentCode & COOKIE_CONSENT_BITMAP.PERFORMANCE);
	}

	get isTargetingCookiesSet () {
		return Boolean(this.consentCode & COOKIE_CONSENT_BITMAP.TARGETING);
	}

	getNewDateString () {
		return new Date(new Date().setDate(182)).toISOString();
	}

	showManageModal () {
		this.manageModal = new Modal({
			id: 'cookieManageModal',
			className: '',
			hideFooter: false,
			hideHeader: true,
			hideOnSwipe: false,
			resetScroll: true,
			// header: `<div class="title">${l('global.simplePhrases.oops')}</div><span class="icon-circle-cross" data-action="cookieClose"></span>`,
			footer: `<button data-action="cookieNo" class="abBtn--blueWhite abBtn abBtn--flex">${l('global.cookie.disclaimer.decline')}</button><button id="closeModal" class="abBtn--blueWhite abBtn abBtn--flex" data-action="cookieSave">${l('global.cookie.disclaimer.manage.accept')}</button>`,
			body: `
<div class="abDisplayMessage abDisplayMessage--column" style="display: flex;">
	<div class="abDisplayMessage__msg">
		<p class="pre-line">${l('global.cookie.disclaimer.tos')}</p>
		<p><div class="abBtn abBtn--blueWhite" data-action="cookieYes">${l('global.cookie.disclaimer.accept')}</div></p>
		<h3>${l('global.cookie.disclaimer.manage.title')}</h3>
		<div>
			<table style="width: 100%">
				<tbody>
					<tr data-action="toggle-more-info" data-target="essential-info">
						<td>${l('global.cookie.disclaimer.manage.essential')}</td>
						<td><div class="switch flex justify-content-center"><input disabled onclick="this.checked = true;" id="essential-cookies" checked class="switch-toggle" type="checkbox"><label for="essential-cookies"></label></div></td>
						<td class="more-info-btn"><i class="icon-chevron-right"></i></td>
					</tr>
					<tr class="more-info"><td colspan="3"><div id="essential-info">${l('global.cookie.disclaimer.manage.essential.info')}</div></td></tr>
					<tr data-action="toggle-more-info" data-target="functional-info">
						<td>${l('global.cookie.disclaimer.manage.functional')}</td>
						<td data-action="toggle-consent"><div class="switch flex justify-content-center"><input id="functional-cookies" class="switch-toggle" type="checkbox"><label for="functional-cookies"></label></div></td>
						<td class="more-info-btn"><i class="icon-chevron-right"></i></td>
					</tr>
					<tr class="more-info"><td colspan="3"><div id="functional-info">${l('global.cookie.disclaimer.manage.functional.info')}</td></tr>
					<tr data-action="toggle-more-info" data-target="performance-info">
						<td>${l('global.cookie.disclaimer.manage.performance')}</td>
						<td data-action="toggle-consent"><div class="switch flex justify-content-center"><input id="performance-cookies" class="switch-toggle" type="checkbox"><label for="performance-cookies"></label></div></td>
						<td class="more-info-btn"><i class="icon-chevron-right"></i></td>
					</tr>
					<tr class="more-info"><td colspan="3"><div id="performance-info">${l('global.cookie.disclaimer.manage.performance.info')}</div></td></tr>
					<tr data-action="toggle-more-info" data-target="targeting-info">
						<td>${l('global.cookie.disclaimer.manage.targeting')}</td>
						<td data-action="toggle-consent"><div class="switch flex justify-content-center"><input id="targeting-cookies" class="switch-toggle" type="checkbox"><label for="targeting-cookies"></label></div></td>
						<td class="more-info-btn"><i class="icon-chevron-right"></i></td>
					</tr>
					<tr class="more-info"><td colspan="3"><div id="targeting-info">${l('global.cookie.disclaimer.manage.targeting.info')}</div></td></tr>
				</tbody>
			</table>
			</div>
		<p><a href="${l('global.navigation.informationLink')}?view=privacyPolicy" class="cookie-notice__readmore">${l('global.cookie.disclaimer.read_more')}</a></p>
	</div>
</div>
`,
			onOpen: () => {
				this.manageModal.html.addEventListener('click', (event) => {
					if (event.target.closest('[data-action="toggle-consent"]')) {
						const target = event.target.querySelector('input');
						if (target) {
							target.checked = !target.checked;
						}
					} else if (event.target.closest('[data-action="toggle-more-info"]')) {
						const parent = event.target.closest('[data-action="toggle-more-info"]');
						const target = qs(`#${parent.dataset.target}`);
						target.closest('.more-info').classList.toggle('more-info--visible');
						qs('.more-info-btn', parent).classList.toggle('more-info-btn--rotate');
					} else if (event.target.dataset.action) {
						this.buttonClick(event.target);
						this.manageModal.close();
					}
				});
			}
		});
		this.manageModal.open();
	}

	buttonClick (button) {
		slideUp(this.html);
		const isYes = button.dataset.action === 'cookieYes';
		const isNo = button.dataset.action === 'cookieNo';

		if (isYes) {
			this.consentCode = COOKIE_CONSENT_BITMAP.FUNCTIONAL | COOKIE_CONSENT_BITMAP.PERFORMANCE | COOKIE_CONSENT_BITMAP.TARGETING;
			logEvent(events.acceptCookies, eventCategories.cookies);
		} else if (isNo) {
			this.consentCode = COOKIE_CONSENT_BITMAP.NONE;
			logEvent(events.declineCookies, eventCategories.cookies);
		} else {
			this.consentCode |= qs('#functional-cookies').checked ? COOKIE_CONSENT_BITMAP.FUNCTIONAL : 0;
			this.consentCode |= qs('#performance-cookies').checked ? COOKIE_CONSENT_BITMAP.PERFORMANCE : 0;
			this.consentCode |= qs('#targeting-cookies').checked ? COOKIE_CONSENT_BITMAP.TARGETING : 0;

			logEvent(events.manageCookies, eventCategories.cookies, { functional: qs('#functional-cookies').checked, performance: qs('#performance-cookies').checked, targeting: qs('#targeting-cookies').checked });

			if (qs('#functional-cookies').checked) {
				logEvent(events.acceptCookies, eventCategories.cookies);
			} else {
				logEvent(events.declineCookies, eventCategories.cookies);
			}
		}

		setCookie('consent', this.consentCode | 0, 182);

		if (this.isTargetingCookiesSet) {
			EventDispatcher.dispatch('cookieNotice:accept', { consentCode: this.consentCode });
		} else {
			EventDispatcher.dispatch('cookieNotice:decline', { consentCode: this.consentCode });
		}
	}

	events () {
		this.html.addEventListener('click', (e) => {
			if (e.target.dataset.action === 'cookieManage') {
				this.showManageModal();
			} else if (e.target.type === 'button') {
				e.stopPropagation();
				this.buttonClick(e.target);
			}
		});
	}
}
