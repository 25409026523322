import 'intersection-observer';
import ResizeObserver from 'resize-observer-polyfill';
import 'web-animations-js/web-animations.min';
import smoothscroll from 'smoothscroll-polyfill';

export function initialisePolyfills () {
	smoothscroll.polyfill();
	if (!window.ResizeObserver) {
		window.ResizeObserver = ResizeObserver;
	}
}
