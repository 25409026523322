import { EventDispatcher, hideElement, isDesktopView, l, loadJavascript, logEvent, qs, qsa, setCookie } from '@utils/toolbox';
import { Modal } from '@js/components/modal';
import { eventCategories, events } from './event';

EventDispatcher.addEventListener('cookieNotice:accept', () => {
	console.log('cookieBasedFeatures:accept');
	console.log('cookieBasedFeatures:accept:loading:elements');
	qsa('[data-cookie]').forEach((element) => {
		element.removeAttribute('data-cookie');
	});

	// Load adform
	if (isDesktopView() && js_params.adFormUrl) {
		console.log('cookieNotice:accept:loading:adform');
		loadJavascript({ src: js_params.adFormUrl }, { async: '', defer: '', id: 'adform-script' });
	}

	// Load GTM
	if (js_params.gtmEnabled) {
		console.log('cookieNotice:accept:loading:GTM');
		loadJavascript(
			{
				src: `/static/v${js_params.version}/assets/resources/build/js/plugins/gtmLoader.js`
			},
			{
				async: '',
				defer: '',
				id: 'gtm-script'
			});
	}
});


EventDispatcher.addEventListener('cookieNotice:blockingModal:show', () => {
	if (window.Main.cookieNotice.isTargetingCookiesSet) {
		return;
	}
	hideElement('#cookie-notice');
	const cookieNoticeHtml = qs('.cookie-notice__msg div').innerHTML;
	let accepted = false;
	const errorModal = new Modal({
		id: 'searchErrorModal',
		className: 'modal--searchError',
		hideFooter: false,
		hideOnSwipe: true,
		resetScroll: true,
		header: `<div class="title">${l('global.simplePhrases.oops')}</div><span class="icon-circle-cross" data-action="cookieClose"></span>`,
		footer: `<button data-action="cookieNo" class="abBtn--whiteBlue abBtn abBtn--flex">${l('error-page.message.goback')}</button><button id="closeModal" class="abBtn--blueWhite abBtn abBtn--flex" data-action="cookieYes">${l('global.simplePhrases.accept')}</button>`,
		body: `
<div class="abDisplayMessage abDisplayMessage--column abDisplayMessage--withImageBg" style="display: flex;">
	<div class="abDisplayMessage__msg">${l('global.cookie.disclaimer.featureRequiresCookies')}<br><br>${cookieNoticeHtml}</div>
</div>
`,
		onOpen: () => {
			errorModal.html.addEventListener('click', (event) => {
				if (event.target.dataset.action === 'cookieYes') {
					window.Main.cookieNotice.consentCode = 7;
					setCookie('consent', 7, 182);
					accepted = true;
					EventDispatcher.dispatch('cookieNotice:blockingModal:accept');
					EventDispatcher.dispatch('cookieNotice:accept');
					logEvent(events.acceptCookies, eventCategories.cookies);
					errorModal.close();
				} else if (['cookieNo', 'cookieClose'].includes(event.target.dataset.action)) {
					EventDispatcher.dispatch('cookieNotice:blockingModal:decline');
					logEvent(events.declineCookies, eventCategories.cookies);
					errorModal.close();
				}
			});
		},
		onClose: () => {
			if (!accepted) {
				EventDispatcher.dispatch('cookieNotice:blockingModal:decline');
				logEvent(events.declineCookies, eventCategories.cookies);
			}
		}
	});
	errorModal.open();
});
